import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  makeStyles,
  Grid,
  Button,
  Box,
  Typography,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftOutlined';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import FileIcon from '@material-ui/icons/DescriptionOutlined';
import OperationResume from './elements/OperationResume';
import {
  fetchOrderDetail,
  fecthOrderInvoices,
  resetOrderDetail,
  downloadOrderSimulation,
} from '../store/actions/orderActions';
import Panel from './elements/Panel';
import InvoiceTableOrderDetails from './tables/InvoiceTableOrderDetails';
import CountryFormatHelper from './elements/CountryFormatHelper';
import Loader from './elements/Loader';
import OrderDocumentsSection from './OrderDocumentsSection';
import { features } from '../config/features';
import SurplusChip from './elements/SurplusChip';
import { COUNTRY_CODE_CL } from '../helpers/constants';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 310px',
    gridTemplateRows: '35px auto auto',
    gridColumnGap: 20,
    gridRowGap: 20,
  },
  backArea: {
    gridRow: '1 / 2',
    gridColumn: '1 / 3',
    marginLeft: 30,
    marginTop: 5,
  },
  cardsArea: {
    gridRow: '2 / 3',
    gridColumn: '1 / 3',
    marginLeft: 30,
    width: 468,
  },
  invoicesArea: {
    gridRow: '3 / 4',
    gridColumn: '1 / 2',
    marginLeft: 30,
  },
  resumeArea: {
    gridRow: '3 / 4',
    gridColumn: '2 / 3',
    marginRight: 30,
  },
  tooltipIcon: {
    marginLeft: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  wordSeparator: {
    marginRight: 9,
  },
  totals: {
    marginRight: 20,
  },
  panelSeparator: {
    margin: '20px 0',
  },
  rowRoot: {
    display: 'grid',
    gridTemplateColumns: '65% 35%',
    gridTemplateRows: 'auto',
    alignItems: 'center',
  },
});

const OrderDetails = props => {
  const { orderId } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [percentageSurplus, setPercentageSurplus] = useState();
  const [currentSurplus, setCurrentSurplus] = useState();
  const businessId = useSelector(state => state.businessState?.business?.id);
  const countryId = useSelector(
    state => state.businessState.business.countryId
  );
  const countryFeatures = features[countryId];
  const order = useSelector(state => state.orderState);
  const { isSudoUser } = useSelector(state => state.uiState);

  const {
    resume,
    invoices,
    orderDetailIsLoading,
    orderInvoicesIsLoading,
    pagination,
  } = order;

  const { page, pageSize } = pagination;

  const updateInvoices = (selectedPage, pageSize) => {
    dispatch(
      fecthOrderInvoices(
        orderId,
        businessId,
        selectedPage + 1,
        pageSize,
        countryId
      )
    );
  };

  useEffect(() => {
    dispatch(resetOrderDetail());
    dispatch(fetchOrderDetail(orderId, businessId, isSudoUser));
    dispatch(
      fecthOrderInvoices(orderId, businessId, page, pageSize, countryId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, businessId, dispatch]);

  useEffect(() => {
    const { retention = 0 } = resume;
    const surplusSum = invoices.reduce((accu, invoice) => {
      return accu + invoice.amountRetention || 0;
    }, 0);
    setCurrentSurplus(surplusSum);

    if (retention) {
      const percentage = (surplusSum * 100) / retention;
      setPercentageSurplus(percentage);
    }
  }, [invoices, resume]);

  const handleDownloadOrderSimulation = () => {
    dispatch(downloadOrderSimulation(businessId, orderId));
  };

  return (
    <Grid container className={classes.root}>
      {countryFeatures.orderDetails.hasDocuments && (
        <Grid item xs={12} className={classes.cardsArea}>
          <OrderDocumentsSection orderId={orderId} variant="small" />
        </Grid>
      )}

      <Grid item className={classes.backArea}>
        <Button
          variant="text"
          component={Link}
          to="/orders"
          data-qa="order-details-back-button-action"
        >
          <ChevronLeftIcon /> Volver
        </Button>
      </Grid>

      <Grid item className={classes.invoicesArea}>
        <Panel
          title={`Detalle de la operación #${orderId}`}
          contentPadding="zero zero sm"
        >
          <InvoiceTableOrderDetails
            tableData={invoices}
            isLoading={orderInvoicesIsLoading}
            countryId={countryId}
            pagination={pagination}
            updateInvoices={updateInvoices}
          />
          <Grid item xs={12} className={classes.totals}>
            <Typography
              variant="h6"
              color="textPrimary"
              align="right"
              component="div"
            >
              <Box fontWeight="fontWeightBold" className={classes.tooltipIcon}>
                <span className={classes.wordSeparator}>Monto Total:</span>
                <CountryFormatHelper
                  value={resume.totalAmount}
                  variant="currency"
                  countryId={countryId}
                  dataQa="order-details-monto-total-string"
                />
                {countryFeatures.invoicesTable.totalAmountTooltip && (
                  <Tooltip title="Valor incluye IVA">
                    <HelpOutlineRoundedIcon
                      color="secondary"
                      fontSize="small"
                      className={classes.tooltipIcon}
                    />
                  </Tooltip>
                )}
              </Box>
            </Typography>
          </Grid>
        </Panel>
      </Grid>

      <Grid item className={classes.resumeArea}>
        <Panel
          title="Resumen de operación"
          titleAlign="center"
          contentPadding="zero sm lg"
        >
          {orderDetailIsLoading ? (
            <Loader />
          ) : (
            <OperationResume
              simulation={resume}
              showButton
              buttonComponent={
                <Button
                  className={classes.downloadLink}
                  onClick={handleDownloadOrderSimulation}
                  variant="text"
                  fullWidth
                  startIcon={<FileIcon />}
                >
                  Descargar Simulación
                </Button>
              }
            />
          )}
        </Panel>
        {countryId === COUNTRY_CODE_CL && (
          <>
            <div className={classes.panelSeparator} />
            <Panel
              title="Resumen de excedentes"
              titleAlign="center"
              contentPadding="zero sm lg"
            >
              {orderDetailIsLoading ? (
                <Loader />
              ) : (
                <Grid item xs={12} container className={classes.rowRoot}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="div"
                  >
                    <Box fontWeight="fontWeightBold">Excedentes vigentes:</Box>
                  </Typography>
                  {orderInvoicesIsLoading || orderDetailIsLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    <SurplusChip
                      currentSurplus={currentSurplus}
                      percentageSurplus={percentageSurplus}
                      countryId={countryId}
                    />
                  )}
                </Grid>
              )}
            </Panel>
          </>
        )}
      </Grid>
    </Grid>
  );
};

OrderDetails.propTypes = {
  orderId: PropTypes.string.isRequired,
};

export default OrderDetails;
